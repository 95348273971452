.bottomSheet {
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  background: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s ease-in-out;
  z-index: 900;
  padding: 0px 20px 10px 20px;
}

.open {
  bottom: 0px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 800;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.closeBtn {
  background: none;
  border: none;
  font-size: 3rem;
  position: absolute;
  font-weight: lighter;
  right: 10px;
  cursor: pointer;
}

.closeButton {
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.imageContainer img {
  max-width: 150px;
  border-radius: 10px;
}

.title {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
}

.paragraph {
  text-align: center;
  margin: 0 0 30px 0;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.button {
  border-radius: 10px;
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.ad {
  color: black;
  background-color: #ffdd33;
  border: none !important;
}

.button.primary {
  border: 1px solid black;
  background: white;
  color: black;
  font-weight: bold;
}

.button svg {
  margin-right: 10px;
}

.button[disabled] {
  cursor: not-allowed;
}

.coin_icon {
  width: 20px;
  height: 20px;
}

.coinBalanceContainer {
  position: absolute;
  top: 15px;
  left: 25px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 3px 3px;
  background-color: #fff;
}

.coinIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.coinBalance {
  font-size: 16px;
  margin-left: 5px;
}

.modal {
  padding: 20px;
}

.modalBody {
  margin-bottom: 20px;
}

.buttonText {
}

.coinWrapper {
  display: flex;
  align-items: center;
}

.coin_icon {
  margin-left: 8px;
}

.customTooltip .tooltip-inner {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 0;
}

.tooltipContent {
  padding: 5px;
  margin: 0;
}

@media (max-width: 600px) {
  .button {
    font-size: 12px;
  }
}
