@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-snap-type: y mandatory;
}
.icon {
  display: block;
  font-size: xx-large;
}

.history-card {
  border-radius: 27px !important;
}

.thumbnail {
  border: 0px solid !important;
  height: 100%;
}

.thumbnail:hover {
  box-shadow: 0px 0px 18px black;
}

.saved-card {
  z-index: 2;
}

.saved-card:hover {
  box-shadow: 0px 0px 18px black;
}

.homepage-row > * {
  margin-bottom: 1rem;
}
.card {
  --bs-card-border-color: none !important;
  --bs-card-border-radius: 0.75rem !important;
}
.card:hover {
  --bs-card-border-color: none !important;
  --bs-card-border-radius: 0.75rem !important;
}
.hover-card {
  background-color: transparent;
  /* -webkit-perspective: 1000px;
  perspective: 1000px; */
  /* width: 100%; */
  aspect-ratio: 1;
  cursor: pointer;
}

.filtered-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 0.75rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.hover-card-inner {
  position: relative;
  width: 144px;
  height: 144px;
  text-align: center;
  border-radius: 0.75rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.filtered-card-inner img,
.hover-card-inner img {
  border-radius: 0.75rem;
}

.search-ip:focus + .search-icon {
  display: none;
}

.search-ip:focus ~ .back-icon {
  display: block;
}

.filtered-card-inner::before,
.hover-card-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3d505;
  z-index: -1;
  border-radius: 0.75rem;
  transform: scale(0.9);
  transition: transform 0.3s;
}

.hover-card:hover .filtered-card-inner,
.hover-card:hover .hover-card-inner {
  transform: translate(-8px, -8px);
  box-shadow: 8px 8px 0px 0px #f3d505;
}

.hover-card:hover .filtered-card-inner::before,
.hover-card:hover .hover-card-inner::before {
  transform: scale(1);
}

@media (max-width: 600px) {
  .hover-card-inner,
  .filtered-card-inner {
    -webkit-transition: none;
    transition: none;
    -webkit-transform-style: flat;
    transform-style: flat;
    box-shadow: none;
  }

  .hover-card:hover .hover-card-inner,
  .hover-card:hover .filtered-card-inner {
    -webkit-transform: none;
    transform: none;
    box-shadow: none;
  }

  .hover-card-inner::before,
  .filtered-card-inner::before {
    box-shadow: none;
    background: transparent;
  }
}

.game-card {
  background-color: white !important;
  color: black;
  border-radius: 0.75rem;
}

@media (max-width: 600px) {
  .game-card {
    background-color: white !important;
    color: white;
    position: relative;
    border-radius: 0.75rem;
  }
}

.grid-autofit-breaks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(134px, 1fr));
  /* row-gap: 1rem; */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*hide scroll bar for chrome, safari, opera*/
.reels::-webkit-scrollbar {
  display: none;
}
/*hide scrollbar for IE , edge, firefox*/
.reels {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: y mandatory; /* Enable vertical snap scrolling */
  overflow-y: scroll;
}
.aspect-video.active {
  height: 100vh; /* Set a fixed height for the active video container */
  overflow-y: scroll; /* Enable vertical scrolling for the active video */
}
.wrapper::-webkit-scrollbar {
  display: none;
}

.player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}
.current-video {
  border-color: #ff0000;
  border-width: 2px;
  border-style: solid;
}

.i {
  height: 46px;
  width: 46px;
  text-align: center;
  list-style: none;
  background-color: bisque;
  border-radius: 50%;
  cursor: pointer;
  padding: 12px;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .corousal img {
    width: min-content;
  }
}
#root {
  overflow-x: hidden;
}
.hidden {
  display: none;
}
.small-screen {
  gap: 3rem;
}

.small-screen-col {
  width: 40%;
  height: 40%;
}
.no-scroll {
  overflow: hidden;
}
.slider .handle.handleNext {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  right: 0;
}
.slider .handle.active {
  cursor: pointer;
}
.slider .handle {
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  width: 4%;
  z-index: 20;
}
.slider .handle.handleNext .indicator-icon {
  transform-origin: 45% 50%;
}
.slider .handle > .indicator-icon {
  align-self: center;
  display: none;
  font-size: 2.5vw;
  height: auto;
  transition: transform 0.1s ease-out 0s;
}
[class*=' icon-'],
[class^='icon-'] {
  speak: none;
  font-family: nf-icon;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  transform: translateZ(0);
}
.wrapper:hover .next-button,
.next-button:hover {
  opacity: 1;
}
.plus-icon-width {
  width: calc(100% + 40px);
}
.bg-gradient-to-r {
  opacity: 0.7;
}
.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  width: calc((width-of-a-single-card) * 10);
  width: min-content;
}
carousel-card {
  width: (width-of-a-single-card);
  scroll-snap-align: start;
}
.carousel ul {
  width: 140px !important;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}
.webkit {
  -webkit-overflow-scrolling: touch;
}
.carousel ul li {
  margin-right: 2px;
}
@media (min-width: 1024px) {
  .carousel {
    display: flex !important;
    overflow-y: hidden !important;
  }
}

@media (max-width: 1023px) {
  .carousel button {
    display: none !important;
  }
  .wrapper {
    overflow-x: auto !important;
    display: flex !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    /* width: 100% !important; */
  }
  .carousel ul {
    padding-left: 8px;
  }
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.multiplayer-modal-wrapper {
  z-index: 9999;
}

@media (max-width: 579px) {
  .modal-content {
    padding: 1rem !important;
    border-radius: 28px 28px 0 0 !important;
    height: 50% !important;
  }
  .multiplayer-modal-wrapper > * {
    padding: 0 !important;
  }
}
.modal {
  z-index: 99999999 !important;
}

.circular-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #f3d505;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: circular-rotation 0.5s linear infinite;
}

@keyframes circular-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slick-slide .tornament-item {
  transform: scale(0.85);
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}

.slick-slide {
  position: relative;
  z-index: -1;
}

.slick-current + div {
  position: relative;
  z-index: 99999;
}

.slick-current + div .tornament-item {
  position: relative;
  z-index: 99999;
  left: -20%;
  width: 140%;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.tournament-wrapper:hover .slick-prev,
.tournament-wrapper:hover .slick-next {
  visibility: visible !important;
  cursor: pointer;
}

.slick-prev {
  /* visibility: hidden !important; */
  left: 1% !important;
  z-index: 999;
  width: fit-content !important;
  top: 40% !important;
}

.slick-next {
  /* visibility: hidden !important; */
  right: 1% !important;
  z-index: 999;
  width: fit-content !important;
  top: 40% !important;
}

.slick-prev::before,
.slick-next::before {
  font-size: 50px !important;
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 10ms;
}
