/* .game-frame {
  position: absolute;
  margin-top: 0px;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow: hidden !important;
} */

.game-frame-header-text {
  text-decoration: none;
  color: black;
}

.game-frame-loading {
  position: fixed;
  /*height: 400px;
  width: 500px;*/
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.tooltip-inner {
  margin: 0px !important;
  padding: 0px !important;
}

.game-card-tooltip {
  border-radius: 0px;
}

.game-card-tooltip-thumbnail {
  border-radius: 0px;
}

.game-card-tooltip-title {
  color: #000000;
  font-weight: bold;
}
.game-card-tooltip-subtitle {
  color: #000000;
}

.tooltip-auto[x-placement^='right'] .arrow::before,
.tooltip-right .arrow::before {
  border-right-color: red !important;
}

.fade {
  opacity: 1;
}

.show {
  opacity: 1;
}

.webkit {
  -webkit-overflow-scrolling: touch;
}
