/* #buttons {
  display: flex;
  flex-direction: row;
} */
html,
body {
  overflow-x: hidden;
}

#cardimg {
  max-width: 165px;
  border-radius: 35px;
  margin-right: 10px;
}
#login {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 35px;
  padding: 6px 16px 6px 16px;
  width: 100%;
  background-color: #0077ff;
  border: 2px solid #0077ff;
  box-shadow: 0px 24px 38px rgba(2, 3, 3, 0.03),
    0px 9px 46px rgba(2, 3, 3, 0.02), 0px 11px 15px rgba(2, 3, 3, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: white;
}
#logo1 {
  display: none;
}
/*popup content*/
.pop {
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.pop_up_header {
  text-align: center;
  align-items: center;
}
#box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#p {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.react-tel-input > .form-control {
  width: 100% !important;
}

.modal-open {
  padding-right: 0 !important;
}

.login-modal > #otp {
  margin-top: 16px !important;
}

#otp {
  margin-top: 8px;
  margin-bottom: 20px;
  width: -webkit-fill-available;
  box-shadow: 0px 4px 5px rgba(2, 3, 3, 0.03), 0px 1px 10px rgba(2, 3, 3, 0.02),
    0px 2px 4px rgba(2, 3, 3, 0.04);
  border-radius: 30px;
  background-color: #f3d505;
  color: black;
  font-weight: 600;
  margin-bottom: 24px !important;
}
#modal {
  top: 20%;
  max-width: 361px;
  margin: auto;
  border-radius: 28px !important;
  height: 346px;
}
#OtpInput {
  display: block;
  position: block;
  max-width: 200px;
  margin: auto;
  width: -webkit-fill-available;
  display: block;
  left: 0;
  border: 1px solid #e7e0ec;
  border-radius: 4px 4px 0px 0px;
  border-bottom-color: #0077ff;
}
#header {
  width: 130px;
  margin: auto;
  padding-bottom: 48.5px;
}
/*#col {
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  height: 96px;
  letter-spacing: 0.8px;
  width: 352px;
  margin-top: 20px;
  margin-bottom: 5%;
}*/
#input {
  height: 40px;
}
#btn {
  text-align: center;
  justify-content: center;
  width: 244px;
  height: 40px;
}

#card {
  width: 100%;
  height: 100%;
  background-color: #f3d505;
  border-bottom-left-radius: 3em 3em;
  border-bottom-right-radius: 3em 3em;
}
.column2 {
  padding: 41px 4px;
}
/*no-link*/
a:link {
  text-decoration: none;
}
/*scroll button css*/
.button {
  padding: 2px 16px;
  background: white;
  color: black;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  letter-spacing: 0.01em;
}
.scrollbtn {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 64%;
}
.chevron {
  position: absolute;
  width: 1.5rem;
  height: 0.25rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}
.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: white;
}
.chevron:before {
  left: 0;
  transform: skewY(30deg);
}
.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

.otp-change-btn {
  all: unset;
  color: #005fcc;
  font-size: 0.75rem;
}
.otp-change-btn:hover {
  cursor: pointer;
}

/* .login-modal > .modal-content,
.otp-modal > .modal-content{
  width: 80%;
} */

.modal-body {
  padding: 0 1.5rem 0 1.5rem !important;
}

.login-modal,
.otp-modal {
  width: 22.5rem !important;
  border-radius: 28px !important;
}

.otp-modal > .container {
  padding: 0 !important;
}

.styled-border {
  background-color: #79747e;
  width: 2rem;
  height: 4px;
  opacity: 40%;
  margin: 0 auto;
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

@media (max-width: 579px) {
  .modal-body {
    padding: 0 !important;
  }
  /*#main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
  }*/

  #input {
    display: none;
  }
  .column2 {
    padding: 0%;
  }

  .column1 {
    background-color: #f3d505;
    align-items: center;
    padding: 0;
    height: 100%;
  }

  .content {
    height: max-content;
    box-shadow: none;
    background-color: #f3d505;
  }
  /*#col {
    width: 345px;
    margin-top: 16px;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
  }*/
  #btn {
    display: none;
  }

  #cardimg {
    max-width: 150px;
    align-items: center;
    margin: auto;
    border-radius: 26px;
    margin-right: 5px;
  }
  #card {
    border-bottom-left-radius: 0em 0em;
    border-bottom-right-radius: 0em 0em;
  }
  .scrollbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0%;
  }
  #logo1 {
    display: block;
    margin-top: 36px;
  }
  #logo {
    display: none;
  }
  .modal:has(.login-modal),
  .modal:has(.otp-modal) {
    display: flex !important;
    justify-content: center !important;
  }
  .login-modal,
  .otp-modal {
    width: 100% !important;
  }
  .modal-dialog {
    position: absolute !important;
    bottom: -100%;
    left: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-height: 100%;
    animation: slide-up 0.6s forwards;
    z-index: 9999;
  }
  @keyframes slide-up {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
  @keyframes slide-down {
    from {
      bottom: 0;
    }
    to {
      bottom: -100%;
    }
  }
  .modal.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal.hide .modal-dialog {
    animation: slide-down 0.8s forwards;
  }
}
