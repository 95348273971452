.user-icon-wrapper {
  background-color: #d3ecff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header1 {
  background-color: #ffffff;
}
.header2 {
  background-color: #eaf5e4;
  height: 349px;
}
.container-left2 {
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 55px;
  letter-spacing: -0.8px;
  color: #000000;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.styled-history-title {
  text-align: center;
  height: fit-content;
  background: #f3d505;
  font-weight: 600;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  z-index: 1;
  font-size: 13px;
  white-space: nowrap;
}

.most-played-wrapper,
.recently-played-wrapper {
  display: grid;
  padding: 8px !important;
  height: 208px;
  min-width: 130px;
  aspect-ratio: 0.8;
}

.most-played-wrapper > *,
.recently-played-wrapper > * {
  grid-column: 1/-1;
  grid-row: 1/-1;
}

#cardImg1 {
  width: 100%;
  height: 100%;
  border-radius: 26px;
  background-image: url('/public/Frame\ 10.png');
  background-size: cover;
  /* filter: blur(2px); */
}
#cardImg1:hover {
  box-shadow: 0px 0px 18px black;
}

#cardImgUrl {
  width: 100%;
  height: 10%;
  border-radius: 26px;
  background-image: url('/public/Frame\ 10.png');
  filter: blur(2px);
}
#cardImg2 {
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
#cardImg2:hover {
  box-shadow: 0px 0px 18px black;
}

.logo .card-img {
  width: '65px';
  height: '55px';
}
.user-img {
  max-width: '15px';
  max-height: '15px';
  margin: auto;
}
.user-name {
  font-style: normal;
  color: #020303;
}
.user-image {
  margin-top: '5px';
}
.user-image:focus,
.user-image:first-child:active,
.user-image:focus-visible {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
.dropdown-menu.show {
  inset: 0px 0px auto auto !important;
  transform: translate3d(-0.5px, 57px, 0px) !important;
}
.row-wrapper {
  max-width: 900px;
  margin: auto;
  height: 100%;
}

.welcome-wrapper {
  height: 100%;
}
.history-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
}
#container-right2 {
  margin-top: 15%;
}

.container-wrapper {
  display: flex;
  align-items: center;
}

.loggedIn-hero-img-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f3d505;
  border-bottom-left-radius: 3em 3em;
  border-bottom-right-radius: 3em 3em;
}

.loggedIn-hero-img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 3em 3em;
  border-bottom-right-radius: 3em 3em;
}

.timepass-banner-logo {
  width: '119px';
  height: '65px';
  margin-top: '0.5rem';
  margin-left: '0.5rem';
}

@media (max-width: 579px) {
  .header2 {
    background-color: #ffffff;
    height: fit-content !important;
  }
  .container-left2 {
    display: none;
  }
  .header1-top-right {
    display: none;
  }
  .user-name {
    display: none;
  }

  .dropdown-arrow {
    display: none !important;
  }
  #container-right2 {
    margin-top: 10px !important;
  }
  #cardImg1 {
    filter: blur(0px);
    height: fit-content;
  }
  #cardImg1:hover {
    box-shadow: 0px 0px 18px black;
  }
  #cardImg2 {
    height: fit-content;
  }
  #cardImg2:hover {
    box-shadow: 0px 0px 18px black;
  }
  /* .LoggedInBanner {
    margin-bottom: 1rem;
  } */

  .container-left2 {
    display: none;
  }
  .container-wrapper {
    display: none;
  }
  .styled-history-title {
    font-size: 0.75rem;
  }
  .row-wrapper {
    width: 100%;
    height: fit-content;
  }
  .most-played-wrapper,
  .recently-played-wrapper {
    height: 100%;
  }
  .welcome-wrapper {
    width: 100%;
    height: fit-content;
    margin: 0 !important;
    padding: 0;
  }
  .history-wrapper {
    width: 100% !important;
    height: fit-content;
  }
  .history-col-wrapper {
    margin: 0;
  }
  .loggedIn-hero-img-wrapper {
    display: none;
  }
  .timepass-banner-logo {
    height: 30px;
    width: 65px;
  }
}
